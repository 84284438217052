
.about-bg-hero{
    height: 100vh;
    background-repeat: repeat; /* 배경 이미지 반복 */
    background-position: center; /* 중앙 정렬 */
    overflow: hidden;
}
.container2{
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    overflow: visible;
  }
.about .hero{
    position: relative;
    padding: 320px 40px 220px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20%); /* 살짝 아래에서 시작 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 원래 위치 */
    }
}

.hero h1 {
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0s; /* 첫 번째 요소는 바로 시작 */
    opacity: 0;
}

.about.hero p {
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0.3s; /* h1이 끝나고 0.3초 뒤에 시작 */
    opacity: 0;
}

.about .hero a {
    display: inline-block;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 600;
    background-color: #00ABE9;
    border-radius: 100px;
    padding: 14px 22px;
    color: white;
    cursor: pointer;
    opacity: 0;
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0.6s; /* p가 끝나고 0.3초 뒤에 시작 */
    transition: transform 0.3s ease, background-color 0.3s ease; /* 확대와 색상 변경 효과를 부드럽게 전환 */
}

.about .hero a:hover {
    transform: scale(1.1); /* 버튼을 살짝 확대 */
    background-color: #008CC0; /* 색상 변경 */
}

.about .hero h1{
    font-size: 64px;
    margin-bottom: 24px;
}
.about .hero p{
    font-size: 20px;
    line-height: 140%;
    max-width: 450px;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(-30%); /* 살짝 아래에서 시작 */
    }
    100% {
        opacity: 1;
        transform: translateY(-45%); /* 원래 위치 */
    }
}

.about .hero img {
    z-index: 100;
    position: absolute;
    cursor: pointer;
    width: 95%;
    top: 50%; /* 부모 요소의 중앙 */
    transform: translateY(-45%);
    right: -270px;
    animation: fadeInUp 1s ease-out;
    transition: transform 0.5s ease; /* 트랜지션으로 호버 애니메이션 효과를 부드럽게 */
}

.about .hero img:hover {
    transform: translateY(-45%) rotate(5deg); /* 살짝 확대하고 회전 */
}
.about .hero span{
    color: #39BDFF;
}
@keyframes rotateSlightly {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(3deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.break-sec{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
}
.break-sec img{
    width: 85px;
    margin-bottom: 32px;
}
.break-sec h3{
    font-size: 56px;
    margin-bottom: 16px;
}
.break-sec p{
    font-size: 22px;
}
.img1, .img2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 110px 0;
}
.img3{
    padding-bottom: 220px;
}
.img1{
    flex-direction: row-reverse;
    padding-top: 220px;
}
.img3{
    padding-top: 110px;
}
.img1 img, .img2 img{
    max-height: 80vh;
    border-radius: 24px;
    box-shadow: -16px 16px 40px #a3b0bc84; /* 기본 그림자 */
}
.img1 img{
    right: -100px;
}
.img1 div, .img2 div{
    box-sizing: border-box;
    padding-left: 10vw;
    width: 45%;
}
.img1 div {
    padding-left: 0;
    padding-right: 8vw;
}
.img1 div h2, .img2 div h2{
    font-size: 40px;
    margin-bottom: 30px;
}
.img1 div p, .img2 div p{
    font-size: 20px;
    line-height: 140%;
}
.img1:hover img, .img2:hover img, .img3:hover img {
    animation: rotateSlightly 1s ease-in-out infinite;
}
.email-cta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 220px 40px;
    gap: 50px;
}
.email-cta h4{
    font-size: 24px;
    color: #00B9D0;
}
.email-cta h2{
    font-size: 48px;
    max-width: 900px;
    text-align: center;
}
.email-cta p{
    font-size: 32px;
}
.email-input{
    display: flex;
    flex-direction: row;
    gap: 1vw;
    justify-content: center;
    align-items: center;
    width: 85%;
}
.email-input input{
    border: none;
    padding: 16px 22px;
    font-size: 18px;
    border-radius: 100px;
    flex-grow: 1;
}
.email-input button{
    color: white;
    background-color: #00ABE9;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    padding: 16px 22px;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
.about-bg-hero{
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    }
    .container2 {
        padding: 0 22px;
        box-sizing: border-box;
    }

    .about .hero {
        flex-direction: column;
        padding: 190px 22px 80px;
        text-align: center;
        box-sizing: border-box;
    }
    .about .hero div{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
    }
    .about .hero h1 {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .about .hero p {
        font-size: 14px;
        max-width: 100%;
        line-height: 140%;
    }

    .about .hero a {
        font-size: 16px;
        margin-top: 30px;
        padding: 12px 20px;
        width: fit-content;
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translateY(0%); /* 살짝 아래에서 시작 */
        }
        100% {
            opacity: 1;
            transform: translateY(-5%); /* 원래 위치 */
        }
    }
    
    .about .hero img {
        z-index: 100;
        position: relative;
        cursor: pointer;
        width: 95%;
        top: 50%; /* 부모 요소의 중앙 */
        transform: translateY(-5%);
        right: 0px;
        animation: fadeInUp 1s ease-out;
        transition: transform 0.5s ease; /* 트랜지션으로 호버 애니메이션 효과를 부드럽게 */
    }
    
    .about .hero img:hover {
        transform: translateY(-5%) rotate(5deg); /* 살짝 확대하고 회전 */
    }
    .break-sec {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .break-sec img {
        width: 60px;
        margin-bottom: 20px;
    }

    .break-sec h3 {
        font-size: 28px;
        margin-bottom: 6px;
    }

    .break-sec p {
        font-size: 14px;
        text-align: center;
    }

    .img1, .img2, .img3 {
        flex-direction: column;
        padding: 40px 22px;
        text-align: center;
        gap: 48px;
    }
    .img1{
        padding-top: 80px;
    }
    .img3{
        padding-top: 40px;
        padding-bottom: 80px;
    }
    .img1 div, .img2 div {
        padding: 0;
        width: 100%;
    }

    .img1 div h2, .img2 div h2 {
        font-size: 22px;
        margin-bottom: 16px;
    }

    .img1 div p, .img2 div p {
        font-size: 13px;
    }

    .img1 img, .img2 img {
        max-height: 50vh;
        margin-top: 20px;
    }

    .email-cta {
        padding: 80px 22px;
        gap: 30px;
        width: 100%;
    }

    .email-cta h4 {
        font-size: 16px;
    }

    .email-cta h2 {
        font-size: 28px;
        max-width: 100%;
    }

    .email-cta p {
        font-size: 16px;
        text-align: center;
    }

    .email-input {
        flex-direction: column;
        width: 100%;
        gap: 10px;
        box-sizing: border-box;
    }

    .email-input input {
        font-size: 16px;
        padding: 12px 16px;
        width: 100%;
        box-sizing: border-box;
    }

    .email-input button {
        font-size: 16px;
        padding: 12px 16px;
        width: 100%;
    }
}
