.header {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ffffff00;
    z-index: 1000;
    font-weight: 500;
    transition: background-color 0.2s ease, box-shadow 0.4s ease; /* 배경색과 그림자 전환 애니메이션 */
  }
  .header.scrolled {
    background-color: #ffffff; /* 스크롤 시 흰색 배경 */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 자연스러운 그림자 추가 */
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 20px 40px;
    box-sizing: border-box;
  }
  
  .logo img {
    position: relative;
    width: 120px;
    padding-top: 2px;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .nav-item {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .nav-item-white {
    color: #fff; /* 하얀 글자색 */
  }
  
  .btn-test {
    background-color: #00abe9;
    border-radius: 100px;
    padding: 14px 22px;
    color: white;
  }
  
  .btn-test-white {
    background-color: transparent;
    border: 1px solid white; /* 하얀색 테두리 */
    color: white;
  }
  
  /* 모바일 스타일 */
@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        padding: 24px 22px 20px;
    }
    .logo-container{
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
    /* 로고 중앙 정렬 */
    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    .logo img{
        width: 90px;
        box-sizing: border-box;
        padding-right: 2px;
        margin-bottom: 2px;
    } 
    .nav-menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }

    .nav-item {
        flex-grow: 1;
        font-size: 12px;
        border-radius: 8px;
        padding: 8px 10px;
        text-align: center;
        background-color: #ECF4F7;
        color: #00abe9;
        font-weight: 500;
    }

    .btn-test {
        flex-grow: 1s;
        font-size: 12px;
        background-color: #00abe9;
        color: #ffffff;
        border: none;
    }
}