
.bg-hero{
    height: 100vh;
    background-repeat: repeat; /* 배경 이미지 반복 */
    background-position: center; /* 중앙 정렬 */
    
}
.hero{
    width: 100%;
    position: relative;
    padding: 320px 40px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow: hidden;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20%); /* 살짝 아래에서 시작 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 원래 위치 */
    }
}

.hero h1 {
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0s; /* 첫 번째 요소는 바로 시작 */
    opacity: 0;
}

.hero p {
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0.3s; /* h1이 끝나고 0.3초 뒤에 시작 */
    opacity: 0;
}

.hero a {
    display: inline-block;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 600;
    background-color: #00ABE9;
    border-radius: 100px;
    padding: 14px 22px;
    color: white;
    cursor: pointer;
    opacity: 0;
    animation: fadeInUp 0.8s ease-out forwards;
    animation-delay: 0.6s; /* p가 끝나고 0.3초 뒤에 시작 */
    transition: transform 0.3s ease, background-color 0.3s ease; /* 확대와 색상 변경 효과를 부드럽게 전환 */
}

.hero a:hover {
    transform: scale(1.1); /* 버튼을 살짝 확대 */
    background-color: #008CC0; /* 색상 변경 */
}

.hero h1{
    font-size: 64px;
    margin-bottom: 24px;
}
.hero p{
    font-size: 20px;
    line-height: 140%;
    max-width: 450px;
}
@keyframes fadeInUp2 {
    0% {
        opacity: 0;
        transform: translateY(0%); /* 살짝 아래에서 시작 */
    }
    100% {
        opacity: 1;
        transform: translateY(-35%); /* 원래 위치 */
    }
}

.hero img {
    position: absolute;
    cursor: pointer;
    right: 18px;
    top: 50%; /* 부모 요소의 중앙 */
    transform: translateY(-35%);
    animation: fadeInUp2 1s ease-out;
    transition: transform 0.5s ease, box-shadow 0.5s ease; /* 트랜지션으로 호버 애니메이션 효과를 부드럽게 */
}

.hero img:hover {
    transform: translateY(-35%) scale(1.05) rotate(3deg); /* 살짝 확대하고 회전 */
}
.hero span{
    color: #FEA532;
}
.igloo-app {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 220px 40px;
}
.igloo-app div{
    display: flex;
    flex-direction: column;
}
.igloo-app .title-small{
    color: #00B9D0;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 34px;
}
.igloo-app h2{
    margin: 0;
    font-size: 45px;
    margin-bottom: 42px;
}
.igloo-app p{
    margin: 0;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 34px;
    max-width: 430px;
    font-size: 20px;
}
.igloo-app a{
    display: inline-block;
    font-size: 18px;
    background-color: #00ABE9;
    color: white;
    padding: 14px 22px;
    border-radius: 100px;
    width: fit-content;
}
.animals{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    padding: 220px 40px;
}
.animals h2{
    font-size: 45px;
    text-align: center;
    line-height: 140%;
}
.animals ul{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 2vw;
}
.animals ul li{
    width: 100%;
}
.animals img{
    cursor: pointer;
    width: 100%;
    flex-grow: 1;
    box-shadow: -16px 16px 40px #a3b0bc84; /* 기본 그림자 */
    border-radius: 12px;
}
@keyframes hoverZoom {
    0% {
        transform: scale(1);
        box-shadow: -16px 16px 40px rgba(163, 176, 188, 0.5); /* 기본 그림자 */
    }
    100% {
        transform: scale(1.05);
        box-shadow: -20px 20px 50px rgba(163, 176, 188, 0.7); /* 강화된 그림자 */
    }
}

.animals img:hover {
    animation: hoverZoom 0.3s ease forwards;
}
.results{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 220px 40px;
    box-sizing: border-box;
}
.results p{
    font-size: 24px;
    color: #00B9D0;
    font-weight: 600;
}
.results h2{
    max-width: 900px;
    font-size: 45px;
    text-align: center;
}
.results ul{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4vw;
    width: 100%;
}
.results ul li{
    display: flex;
    flex-direction: column;
}
.results ul li img{
    width: 100%;
    box-shadow: -16px 16px 40px #a3b0bc84; /* 기본 그림자 */
    border-radius: 24px;
}
.results ul li img:hover {
    animation: hoverZoom 0.3s ease forwards;
}
.igloo-app img{
    box-shadow: -16px 16px 40px #a3b0bc84; /* 기본 그림자 */
    border-radius: 24px;
}

/* igloo-app 이미지에 호버 애니메이션 적용 */
.igloo-app img:hover {
    animation: hoverZoom 0.3s ease forwards;
}
.last-cta{
    background-color: #00ABE9;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding: 220px 40px;

}
.last-cta h4{
    font-size: 48px;
    color: white;
    margin: 0;
}
.last-cta a{
    display: inline-block;
    width: fit-content;
    font-size: 24px;
    color: white;
    border: 1px solid white;
    padding: 14px 22px;
    border-radius: 8px;
    box-sizing: border-box;
}

/* 모바일 스타일 추가 */
@media (max-width: 768px) {
    .bg-hero {
        background-size: cover;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
    .hero {
        flex-direction: column;
        padding: 190px 22px 80px;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 48px;
    }

    .hero h1 {
        font-size: 36px;
    }

    .hero p {
        font-size: 14px;
        max-width: 100%;
    }

    .hero a {
        margin-top: 30px;
        font-size: 15px;
    }

    @keyframes fadeInUp2 {
        0% {
            opacity: 0;
            transform: translateY(0%); /* 살짝 아래에서 시작 */
        }
        100% {
            opacity: 1;
            transform: translateY(0%); /* 원래 위치 */
        }
    }
    
    .hero img {
        position: relative;
        cursor: pointer;
        width: 70%;
        transform: none;
        right: 6px;
        animation: none;
        transition: none; /* 트랜지션으로 호버 애니메이션 효과를 부드럽게 */
    }
    
    .hero img:hover {
        transform: translateY(0%) scale(1.00) rotate(0deg); /* 살짝 확대하고 회전 */
    }

    .igloo-app {
        display: flex;
        flex-direction: column;
        padding: 80px 22px;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 48px;
    }
    .igloo-app div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .igloo-app div a{
        font-size: 15px;
        font-weight: 500;
    }
    .igloo-app .title-small{
        font-size: 16px;
    }
    .igloo-app h2 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .igloo-app p {
        font-size: 14px;
    }
    .igloo-app img{
        width: 70%;
    }

    .animals {
        padding: 80px 22px;
        gap: 48px;
    }

    .animals h2 {
        font-size: 28px;
    }

    .animals ul {
        flex-direction: column;
        gap: 40px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .animals ul li{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .animals ul li img{
        align-items: center;
        width: 80%;
    }
    .results {
        padding: 80px 22px;
        gap: 40px;
    }

    .results h2 {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .results p {
        font-size: 16px;
    }

    .results ul {
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .results ul li img{
        border-radius: 100px;
    }

    .last-cta {
        gap: 30px;
        padding: 80px 22px;
    }

    .last-cta h4 {
        font-size: 22px;
    }

    .last-cta a {
        font-size: 16px;
        border-radius: 100px;
        font-weight: 600;
        padding: 12px 22px;
    }
}
