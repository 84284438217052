.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000E13;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 500;
  }
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 80px 40px;
    box-sizing: border-box;
  }
  
  .footer .logo img {
    position: relative;
  }
  
  .footer .nav-menu {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .footer .nav-item {
    text-decoration: none;
    color: #f7f7f7;
    font-size: 18px;
    transition: color 0.3s;
  }
  .footer .btn-test{
    background-color: #00ABE9;
    border-radius: 100px;
    padding: 14px 22px;
    color: white;
  }

  @media (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 80px;
      }
      .footer .logo-container{
        display: flex;
        height: 100%;
        padding-top: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }
      .footer .nav-menu {
        display: none;
      }
    }