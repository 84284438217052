.container{
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
}
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
h1, h2, h3, h4, p{
  padding: 0;
  margin: 0;
}
a{
  text-decoration: none;
}
.bgcolor-sky{
  background-color: #F4F8F9;
}

@media (max-width: 768px) {
  .container{
    padding: 0 22px;
  }
}